import React, { FC, HTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import Style from "./css/Slider.module.css";


export interface SliderProps extends HTMLAttributes<HTMLDivElement> {
    children:any
    auto:number
    leftArrow:any
    rightArrow:any
    Dots:boolean
    Arrow:boolean
}


export const Slider:FC<SliderProps> = ({ children, auto,leftArrow,Dots=true,Arrow=true,
    rightArrow }) => {
  const isSlide:any = useRef();
  const Scrollable:any = useRef();
  const [ScrollPosition, setScrollPosition] = useState(0);
  const ScrollLeft = useCallback(() => {
    if ( ScrollPosition ===Scrollable?.current?.offsetWidth * (children?.props.children.length - 1)) {
      return Scrollable?.current?.scrollTo(
        0,
        0
      );
    }
    Scrollable?.current?.scrollBy(Scrollable?.current?.clientWidth, 0);
  }, [ScrollPosition,children]);
  const ScrollRight = () => {
    if (!ScrollPosition) {
      return Scrollable?.current?.scrollTo(
        Scrollable?.current?.scrollWidth,
        0
      );
    }
    Scrollable?.current?.scrollBy(-Scrollable?.current?.clientWidth, 0);
  };

  //for auto slide
  useEffect(() => {
    const interval = setInterval(() => {
      ScrollLeft();
    }, auto || 1000);
    return () => clearInterval(interval);
  }, [ScrollLeft, auto]);

  // for mouse listening for slide pause
  const mouseOverHandler = (e:any) => {
    if (e.type === "mouseover") {
      isSlide.current = true;
    } else if (e.type === "mouseleave") {
      isSlide.current = false;
    }
  };

  // Dots click actions
  const navigateSlider = (e:any) => {
    const scrollLocation =
      Scrollable?.current?.clientWidth * +e.target.dataset.banner;
    Scrollable?.current?.scrollTo(scrollLocation, 0);
  };

  return (
    <div
      className={Style.Slider}
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseOverHandler}
    >
      <div
        onScroll={(e:any) => {setScrollPosition(e.target.scrollLeft);
        
        }}
        ref={Scrollable}
        id="SlideContainer"
        className={Style.SlideWarper}
      >
          {children}
        {/*  arrows for scroll */}
      </div>
      {Arrow&&<><span onClick={ScrollRight} className={`${Style.leftArrow} ${!leftArrow&&Style.default}`}>
      {leftArrow||<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/><path d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"/></svg>}
      </span>
      <span onClick={ScrollLeft} className={`${Style.rightArrow} ${!rightArrow&&Style.default}`}>
        {rightArrow||<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"/></svg>}
      </span></>}
      {Dots&&<div className={Style.DotsWarper}>
        {children?.props.children.map((_item:any, index:number) => {
          return (
            //TODO: Animate the Dotes
            <div
              data-banner={index}
              className={`${Style.Dots} ${
                ScrollPosition / Scrollable?.current?.offsetWidth === index &&
                Style.activeDots
              }`}
              key={index}
              onClick={navigateSlider}
            ></div>
          );
        })}
      </div>}
    </div>
  );
};

